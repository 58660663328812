/*
    Available packages
    packages: ['club', 'transport', 'users', 'companies'],
 */

export default class Config {
    static MODULE_CLUB = 'club';
    static MODULE_TRANSPORT = 'transport';
    static MODULE_SHIPPING = 'shipping';
    static MODULE_USERS = 'users';
    static MODULE_COMPANIES = 'companies';
    static MODULE_WAREHOUSE = 'warehouse';
    static MODULE_ACADEMIC = 'academic';
    static MODULE_STORE = 'store';
    static MODULE_SIPAN = 'sipan';

    //Sherlock
    /*
    static appName = 'Sherlock';
    static appCode = 'sherlock';
    static apiPath = 'https://core.distractedbees.com/api/v1/';
    static authPath = 'https://core.distractedbees.com/oauth/';
    static clientSecret = 'XQ7HLbNMYVZpjWsci6auiPeDBeH3Sa7y8BJjsSsq';
    static clientID = 2;
    static packages = ['users', 'companies', 'transport'];
    static sockets = {
        authSocket : 'https://core.distractedbees.com/api/v1/auth-socket',
        port: 3030,
        socketHost: 'adviser.geekadvice.pe',
        appId: 563004,
        publicKey: 'c0043b4c6b25f0dff860',
    };
    /**/

    //Katax
    /*
    static appName = 'KATAX';
    static appCode = 'katax';
    static apiPath = 'https://core.katax.pe/api/v1/';
    static authPath = 'https://core.katax.pe/oauth/';
    static clientSecret = 'I7wzF0WuJecJnKv05Xd6D4gfS35vAuOdNuX5h7za';
    static clientID = 2;
    static packages = ['transport', 'companies', 'users', 'sales', 'warehouse', 'store'];
    static sockets = {
        authSocket : 'https://core.katax.pe/broadcasting/auth',
        port: 3030,
        socketHost: 'adviser.geekadvice.pe',
        appId: 768305,
        publicKey: 'c67c3b4c6b25f0dff860',
    };
    /**/

    //Jean Harzic
    /*
    static appName = 'Jean Harzic';
    static appCode = 'harzic';
    static apiPath = 'https://core.jeanharzic.edu.pe/api/v1/';
    static authPath = 'https://core.jeanharzic.edu.pe/oauth/';
    static clientSecret = 'N2YYNwv2ma5G9eXPI4243cGL0lEasXFgi6aZZPEk';
    static clientID = 2;
    static packages = ['warehouse', 'companies', 'users', 'academic'];
    static sockets = {
        authSocket : 'https://core.geekadvice.pe/broadcasting/auth',
        port: 3030,
        socketHost: 'adviser.geekadvice.pe',
        appId: 768305,
        publicKey: 'c67c3b4c6b25f0dff860',
    };
    /**/

    //Delivery Express Perú
    /*
    static appName = 'Delivery Express';
    static appCode = 'delivery';
    static apiPath = 'https://core.deliveryexpress.pe/api/v1/';
    static authPath = 'https://core.deliveryexpress.pe/oauth/';
    static clientSecret = 'qlNmIWsMrKYtpculbUL9wdAWhZJyxGdcug8ET4T5';
    static clientID = 2;
    static packages = ['warehouse', 'transport', 'companies', 'users', 'shipping'];
    static sockets = {
        authSocket : 'https://core.cast.com.pe/broadcasting/auth',
        port: 3030,
        socketHost: 'adviser.geekadvice.pe',
        appId: 3123,
        publicKey: 'e1234b4c6b25f0dff860',
    };
    /**/

    //Cast Delivery
    /*
    static appName = 'Cast Delivery';
    static appCode = 'cast';
    static apiPath = 'https://core.cast.com.pe/api/v1/';
    static authPath = 'https://core.cast.com.pe/oauth/';
    static clientSecret = 'MQTfP19fTqEhfkKU21SHRkpuHiLPCrjXkpmtebY0';
    static clientID = 2;
    static packages = ['warehouse', 'companies', 'users', 'shipping', 'store'];
    static sockets = {
        authSocket : 'https://core.cast.com.pe/broadcasting/auth',
        port: 3030,
        socketHost: 'adviser.geekadvice.pe',
        appId: 3123,
        publicKey: 'e1234b4c6b25f0dff860',
    };
    /**/

    //Geek Advice
    /*
    static appName = 'Geek Advice';
    static appCode = 'geekadvice';
    static apiPath = 'https://core.geekadvice.pe/api/v1/';
    static authPath = 'https://core.geekadvice.pe/oauth/';
    static clientSecret = 'UWc1cMNauODUdj4gSGtVzQlBWNNH9jmydNsgVL7o';
    static clientID = 2;
    static packages = ['warehouse', 'transport', 'companies', 'users', 'delivery', 'store', 'club'];
    static sockets = {
        authSocket : 'https://core.geekadvice.pe/broadcasting/auth',
        port: 3030,
        socketHost: 'adviser.geekadvice.pe',
        appId: 868305,
        publicKey: 'a67c3b4c6b25f0dff860',
    };
    /**/

    //Geek Advice DEV
    /*
    static appName = 'Geek Advice DEV';
    static appCode = 'geekadvice_dev';
    static apiPath = 'https://dev-core.geekadvice.pe/api/v1/';
    static authPath = 'https://dev-core.geekadvice.pe/oauth/';
    static clientSecret = '4bEoczD3blaJBwJY97iuCd9IFsDu5N330pb9vtQ3';
    static clientID = 2;
    //static packages = ['warehouse', 'transport', 'companies', 'users', 'delivery', 'store', 'club'];
    static packages = ['companies', 'users'];
    static sockets = {
        authSocket : 'https://dev-core.geekadvice.pe/broadcasting/auth',
        port: 3030,
        socketHost: 'adviser.geekadvice.pe',
        appId: 868305,
        publicKey: 'a67c3b4c6b25f0dff860',
    };
    /**/

    //Country
    static appName = 'Country Club La Villa';
    static appCode = 'country';
    static apiPath = 'https://core.countryclublavilla.pe/api/v1/';
    static authPath = 'https://core.countryclublavilla.pe/oauth/';
    static clientSecret = 'ls17spDCZIJW7PcYiHd2IA5qYJQm3WJ6a1RimT5m';
    static clientID = 4;
    static culqiKey = "pk_live_oAyz9iFjSKgTesNa";
    static packages = ['club', 'users', 'warehouse', 'store'];
    /**/

    //SIPAN
    /*
    static appName = 'Sipan Soft';
    static appCode = 'sipan';
    static apiPath = 'http://179.43.96.228:8000/api/v1/';
    static authPath = 'http://179.43.96.228:8000/oauth/';
    static clientSecret = 'ohmhiJkcRG1fF71AQtWCbA3uKkcOwrRgLB6cJXuB';
    static clientID = 2;
    static packages = ['sipan'];
    /**/

    //Localhost
    /*
    static appName = 'Geek Advice';
    static appCode = 'geekadvice';
    static apiPath = 'http://localhost:8000/api/v1/';
    static authPath = 'http://localhost:8000/oauth/';
    static clientSecret = 'CDbQ6RlWZp2aIKmc7XywOVA7duShOs198OMzFJWm';
    //static clientSecret = 'eew6VXhN14semi8NKDyO8nDAhkEcnmjSWz8IQXf1'; //Sipan
    //static clientSecret = 'ohmhiJkcRG1fF71AQtWCbA3uKkcOwrRgLB6cJXuB'; //Sipan 2
    static clientID = 2;
    static culqiKey = "pk_test_YFH4BdNbDf6Kggx6";
    static packages = ['users', 'companies', 'store', 'warehouse', 'club', 'transport'];
    //static packages = ['sipan', 'users'];
    static sockets = {
        //authSocket : 'https://core.katax.pe/broadcasting/auth',
        authSocket : 'http://localhost:8000/broadcasting/auth',
        port: 3030,
        socketHost: 'adviser.geekadvice.pe',
        appId: 563103,
        publicKey: 'a1287563103jd5kb25f08rt',
    };
    /**/

    static appSettings = {
       dateFormatUser:"DD/MM/YYYY",
       dateFormatServer:"YYYY-MM-DD",
    };
}

