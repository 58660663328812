import React, {createContext} from 'react';

const AuthContext = createContext({});

class AuthContextProvider extends React.Component {
    state = {
        cart: {total: 0, items: []},
        user: null,
        selectedCompany: null,
        deliveryPlace: null,
        navigation: null,
        connectionStatus:'Conectando...'
    };

    constructor(props) {
        super(props);
    }

    setDeliveryPlace = (deliveryPlace: Object) => {
        this.setState({deliveryPlace});
    }

    setOrderID = (id: Number) => {
        let {cart} = this.state;
        cart.id = id;
        this.setState({cart})
    }
    addProduct = (product) => {
        let {cart} = this.state;

        const item = cart.items.find(item => item.id === product.id);

        if (item) {
            item.quantity++;
        } else {
            product.quantity = 1;
            cart.items.push(product);
        }

        cart.total += product.price;
        this.setState({cart});
    }

    clearCart = () => {
        this.setState({cart: {total: 0, items: []}});
    }

    setNavigation = (navigation) => {
        this.setState({navigation});
    }

    setConnectionStatus = (connectionStatus) => {
        this.setState({connectionStatus});
    }

    setUser = (user) => {
        const selectedCompany = user.companies[0];
        this.setState({user, selectedCompany});
    }

    render() {
        const {cart, user, navigation, deliveryPlace, selectedCompany, connectionStatus} = this.state;
        return (
            <AuthContext.Provider
                value={{
                    connectionStatus,
                    cart,
                    deliveryPlace,
                    navigation,
                    user,
                    selectedCompany,
                    setUser: this.setUser,
                    setOrderID: this.setOrderID,
                    addProduct: this.addProduct,
                    setNavigation: this.setNavigation,
                    clearCart: this.clearCart,
                    setDeliveryPlace: this.setDeliveryPlace,
                    setConnectionStatus: this.setConnectionStatus
                }}
            >
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

export {AuthContextProvider};
export default AuthContext



