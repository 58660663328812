import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from "antd";
import './styles.css';
import PropTypes from "prop-types";

const LoadingIndicator = ({message, overlay, size}) => (
    <div className={`loader-container ${overlay ? 'overlay' : ''}`}>
        <Spin indicator={<LoadingOutlined spin/>} size={size}/>
        {message===false?null:<span>{message ? message : 'Cargando...'}</span>}
    </div>
);

LoadingIndicator.defaultProps = {
    overlay: true,
    size: 'large'
};

export default LoadingIndicator;
