class Browser {
    static getCookie(name) {
        let value = "; " + document.cookie;
        let parts = value.split("; " + name + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
    }

    static setCookie(cname, value, expiration){
        let d = new Date();
        d.setTime(d.getTime() + (expiration * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + value + ";" + expires + ";Path=/";
    }

    static deleteCookies(name){
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
}

export default Browser;
