import './index.css'

import React from 'react'
import {render} from 'react-dom'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import axios from 'axios';
import {notification} from "antd";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './App/Loadable';
import Config from "./Config";
import Auth from "./containers/Auth/Loadable";
import Browser from "./utils/Browser";
import {AuthContextProvider} from "./Context/AuthContext";

const token = Browser.getCookie('token');

if (token) {
  axios.defaults.baseURL = Config.apiPath;
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.message !== 'Network Error' && error.response) {
      switch (error.response.status) {
        case 401:
          if (window.location.pathname.indexOf('/login') === -1)
            window.location = '/login';
          break;
        case 403:
          notification.warn({
            message: 'Acción restringida',
            description: 'No tienes permiso para ver esta información.',
            duration: 0,
          });
          break;
        default:
          return Promise.reject(error);
      }
    } else {
      notification.warn({
        message: 'Error de conexión',
        description: 'No pudimos conectar con el servidor, por favor verifica tu conexión a internet y que el servicio esté activo.',
        duration: 0,
      });
    }
  });
} else {
  if (window.location.pathname.indexOf('/login') === -1)
    window.location = '/login';
}

Sentry.init({
  dsn: "https://f7d0e45113a44ae0a2e6be0b85c2f862@o476810.ingest.sentry.io/5520165",
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  tracesSampleRate: 0.5,
});

render(<AuthContextProvider>
  <Router>
    <Switch>
      <Route exact path="/login" component={Auth}/>
      <Route path="/" component={App}/>
    </Switch>
  </Router>
</AuthContextProvider>, document.querySelector('#app'));
